import mixins from '@/mixins'
import course from '@/mixins/course'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
import { courseMainTableHead } from '../../../courses/helpers/main'

export default {
  name: 'courses-main',
  mixin: [mixins, course],
  data () {
    return {
      page: 1,
      pageCount: 20,
      itemsPerPage: 20
    }
  },
  computed: {
    ...mapGetters({
      list: 'courses/list',
      loading: 'courses/listLoading',
      lengthList: 'courses/listLength',
      skip: 'courses/skip',
      limit: 'courses/limit',
      setting: 'courses/setting',
      categories: 'courses/categories',
      types: 'courses/types',
      isLoading: 'courses/isLoading',
      filterSearchInput: 'courses/filterSearchInput',
      filterCountries: 'courses/filterCountries',
      filterType: 'courses/filterType',
      filterStatus: 'courses/filterStatus',
      filterStore: 'courses/filterStore',
      profile: 'profile/profile',
      accessCountries: 'profile/accessCountries',
    }),
    courseMainTableHead
  },
  created () {
    this.fetchList()
    if (this.list) {
      if (this.skip !== 0) {
        this.page = Math.round(this.skip / this.limit) + 1
      }
    }
    if (!this.categories) {
      this.fetchCategories()
    }
    if (!this.types) {
      this.fetchTypes()
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'courses/GET_COURSES',
      fetchCategories: 'courses/COURSES_GET_CATEGORIES',
      fetchTypes: 'courses/COURSES_GET_TYPES'
    }),
    ...mapMutations({
      changeSkip: 'courses/COURSES_LIST_CHANGE_SKIP',
      changeStepLock: 'courses/COURSES_CHANGE_LOCK_STEP',
      changeFilter: 'courses/COURSES_FILTER_CHANGE'
    }),
    changeFilterLocal (value, type) {
      const obj = {
        type: type,
        value: value
      }
      this.changeFilter(obj)
      this.changeSkip(0)
      this.sendRequest()

      this.page = 1
    },
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList()
    },
    sendRequest: debounce(function () {
      this.fetchList()
    }, 1000)
  }
}
